import { SHIPPED_SHIELD_SKU } from '@/data/constants/SHIPPED_SHIELD_SKU'
import { WORRY_FREE_DELIVERY_SKU } from '@/data/constants/WORRY_FREE_DELIVERY_SKU'

export const isShippingProtection = (item: { sku: string }) => (
  item.sku.includes(SHIPPED_SHIELD_SKU)
  || item.sku.includes(WORRY_FREE_DELIVERY_SKU)
)

export const isNotShippingProtection = (item: { sku: string }) => (
  !isShippingProtection(item)
)
