import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { DMPAnalyticsEventContext } from '@/hooks/DmpAnalytics/pushDMPAnalyticsEvent'
import { log } from '@/services/Log'
import { useEffect, useMemo, useState } from 'react'

const sendPageView = async ({
  url,
  context,
}:{
  url: string
  context: DMPAnalyticsEventContext
}) => {
  log(`sendPageView: Sending page view for ${url}`)
  const [
    { sendDMPAnalyticsEvent },
  ] = await allPromisesWithRetries(() => [
    import('@/hooks/DmpAnalytics/pushDMPAnalyticsEvent'),
  ])
  sendDMPAnalyticsEvent({
    eventType: 'pageview',
    url,
    items: [],
    context,
  })
}

export const usePageView = ({
  context,
  shouldTrack = true,
}: {
  context: DMPAnalyticsEventContext
  shouldTrack?: boolean
}) => {
  const [sent, setSent] = useState<string>('')
  const stringifiedContext = useMemo(() => JSON.stringify(context), [context])
  useEffect(() => {
    if (
      shouldTrack
      && sent !== stringifiedContext
    ) {
      setSent(stringifiedContext)
      sendPageView({
        url: window.location.href,
        context,
      })
        .catch((e) => log(`sendPageView: Error sending page view: ${String(e)}`))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sent, stringifiedContext, shouldTrack])
}
